<template>
  <div>
    <span class="uppercase font-bold">Step</span>
    <div class="font-bold text-gray-600 flex items-center">
      <span class="text-3xl text-pink-500">{{currentStep}}</span>
      <span class="mx-1 text-xl">of</span>
      <span class="text-xl">{{stepCount}}</span>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      stepCount: Number,
      currentStep: Number
    },
    name: "StepsCounter"
  }
</script>
